import { Query } from "@graphql-types";
import CollectionTemplate from "@shared/collectionTemplate";
import { useStore } from "@state/store";
import { getBundle } from "@util/helper";
import { BreadcrumbPageContext } from "@util/types";
import { graphql } from "gatsby";
import React from "react";

interface Props {
  pageContext: BreadcrumbPageContext;
  data: Query;
}

const BundlesTemplate = ({ data, pageContext }: Props) => {
  if (data == null) return null;
  const pageData = data.allSanityBundles.nodes[0];
  if (pageData == null) return null;

  //Check bundles for current bundle
  const { bundles } = useStore();

  const existingBundle = getBundle(pageData._id);

  if (existingBundle == null || existingBundle == undefined) {
    const defaultBundle = {
      id: pageData._id,
      title: pageData.title,
      price: pageData.price,
      totalProductsAllowed: pageData.maxProducts,
      products: [],
    };
    useStore.setState({
      bundles: [...bundles, defaultBundle],
    });
  }

  const bundleProduct = () => {
    if (pageData.bundleProductNZ) {
      return pageData.bundleProductNZ;
    }
    if (pageData.bundleProductAU) {
      return pageData.bundleProductAU;
    }
    return undefined;
  };

  const bundleProducts = () => {
    if (pageContext.iban == "au") {
      return pageData.productsAU;
    }

    return pageData.productsNZ;
  };

  return (
    <>
      {/* <Hero sanityHero={pageData.hero} /> */}
      <CollectionTemplate
        isBundle
        isShopAll
        hero={pageData?.hero}
        categoryList={pageData?.categories}
        pageContext={pageContext}
        iban={pageContext?.iban}
        productList={bundleProducts()}
        bundleId={pageData?._id!}
        bundleProduct={bundleProduct()}
      />
    </>
  );
};

export default BundlesTemplate;

export const query = graphql`
  query BundlesQuery($iban: String, $slug: String) {
    allSanityBundles(
      filter: {
        region: { iban: { eq: $iban } }
        slug: { current: { eq: $slug } }
      }
    ) {
      nodes {
        region {
          iban
        }
        slug {
          current
        }
        maxProducts
        price
        title
        description {
          _rawBlocks
        }
        hero {
          ...sanityHero
        }
        bundleProductNZ {
          ...sanityProductPreview
        }
        bundleProductAU {
          ...sanityProductPreviewAu
        }
        productsNZ {
          ...sanityProductPreview
        }
        productsAU {
          ...sanityProductPreviewAu
        }
        _id
        _key
      }
    }
  }
`;
